import styled from "styled-components";


export const MarketContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    p {
        margin-top: 60%;
        font-size: 18px;
        font-weight: 700;
    }
`;