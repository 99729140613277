import styled from "styled-components";
import {Link} from "react-router-dom";


export const TasksPageContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;


export const TasksTitle = styled.p`
    margin-bottom: 20px;
    margin-top: 20px;


    font-family: 'GothamMedium', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;


`
export const LinkToInvite = styled(Link)`
    position: relative;
    margin-top: 30px;
    cursor: pointer;
    outline: none;
    width: calc(100% - 20px);
    max-width: 500px;

    & > img {
        width: 100%;
        height: auto;
    }
`;

export const InviteLinkText = styled.div`

    width: 200px;

    position: absolute;
    top: 30px;
    left: 20px;

    & > .InviteLinkText1 {
        font-family: 'GothamMedium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 30px;
        color: #000;
        margin-bottom: 5px;
    }

    & > .InviteLinkText2 {
        font-family: 'GothamMedium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        color: #AD9449;

    }
`


export const TaskList = styled.div`
    width: calc(100% - 20px);
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

`

export const TaskContainer = styled.div`
    cursor: pointer;
    position: relative;
    margin-top: 7px;
    width: calc(50% - 5px);
    height: 180px;
    background: ${props => props.task.reward_received ? '#132017' : props.task.completed ? 'linear-gradient(250.35deg, #B57E10 -23.78%, #B57E10 -3.87%, #E5C35C 37.49%, #F9DF7B 57.18%, #FFF3A6 71.38%, #F9DF7B 87.01%, #B57E10 108.32%);' : '#1B1A19'};
    padding: 10px;
    border-radius: 5px;
    color: ${props => props.task.completed && !props.task.reward_received ? '#555' : 'auto'};

    & > p {
        margin-top: 20px;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
    }

    & > .task-reward {

        position: absolute;
        bottom: 10px;
        left: 10px;

        background: #363636;
        min-width: 80px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        padding-left: 8px;
        padding-right: 5px;

        &::after {
            content: '';
            display: inline-block;
            width: 17px;
            height: 17px;
            background: url('/images/emblem.svg') no-repeat center center;
            background-size: contain;
            margin-left: 10px;
        }
    }
    & > button {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-family: 'GothamMedium', sans-serif;
        background: linear-gradient(250.35deg, #B57E10 -23.78%, #B57E10 -3.87%, #E5C35C 37.49%, #F9DF7B 57.18%, #FFF3A6 71.38%, #F9DF7B 87.01%, #B57E10 108.32%);;
        color: #555;
        padding-bottom: 5px;
        width: 90%;
        height: 30px;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-size: 12px;
        
        &::after {
            content: '';
            display: inline-block;
            width: 17px;
            height: 17px;
            background: url('/images/emblem.svg') no-repeat center center;
            position: relative;
            top: 3px;
            left: 3px;
            
        }
    }
`;


export const ImgContainer = styled.div`
    margin-top: 14px;
    width: 50px;
    height: 50px;
    background: ${props => props.task.reward_received ? '#0F1812' : props.task.completed ? 'linear-gradient(250.35deg, #9A690D -23.78%, #9A690D -3.87%, #C2A149 37.49%, #D4BA63 57.18%, #E2D191 71.38%, #D4BA63 87.01%, #9A690D 108.32%);\n' : '#3A3A3A'};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`


export const IsDoneIMG = styled.img`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
`

export const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 1000;
`;

export const ModalContainer = styled.div`
    position: relative;
    background: #2F2F2F;
    width: 100%;
    max-width: 500px;
    min-height: 400px;
    border-radius: 10px 10px 0 0;
    padding: 20px;
    animation: ${props => props.closing ? "slideDown" : "slideUp"} 0.3s ease-out;

    @keyframes slideUp {
        from {
            transform: translateY(100%);
        }
        to {
            transform: translateY(0);
        }
    }

    @keyframes slideDown {
        from {
            transform: translateY(0);
        }
        to {
            transform: translateY(100%);
        }
    }
`;

export const TaskBrandLogo = styled.img`
    position: absolute;
    left: auto;
    right: auto;
    top: 30px;
`

export const ModalContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    & > .img-container {
        position: relative;
        z-index: 2;
        margin-top: 10px;
        width: 60px;
        height: 60px;
        background: ${props => props.isDone ? '#0F1812' : '#3A3A3A'};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        & > img {
            width: 50%;
            height: auto;
        }
    }

    & > .task-reward {

        margin-top: 20px;

        background-color: #434343;
        min-width: 80px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        padding-left: 8px;
        padding-right: 5px;

        &::after {
            content: '';
            display: inline-block;
            width: 17px; /* Ширина изображения */
            height: 17px; /* Высота изображения */
            background: url('/images/emblem.svg') no-repeat center center;
            background-size: contain;
            margin-left: 10px;
        }
    }

    & > h3 {
        font-family: 'GothamMedium', sans-serif;
        font-size: 18px;
        margin-top: 20px;
        text-align: center;
    }

    & > p {
        font-size: 14px;
        margin-top: 20px;
        text-align: center;
    }

    & > button {
        position: absolute;
        bottom: 35px;
        font-family: 'GothamMedium', sans-serif;
        background-color: #fff;
        color: #000;
        width: 90%;
        height: 50px;
        margin: 0 auto;
        padding: 10px 20px;
        border: none;
        border-radius: 50px;
        cursor: pointer;
        font-size: 20px;
    }
`;


export const TaskProgress = styled.div`
    position: absolute;
    top: 2px;
    right: 5px;
    font-family: 'GothamMedium', sans-serif;
    font-size: 11px;
    color: ${props => props.task.completed && !props.task.reward_received ? '#555' : 'auto'};
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const TaskLimit = styled.div`
    position: absolute;
    top: 2px;
    left: 0px;
    font-family: 'GothamMedium', sans-serif;
    font-size: 11px;
    color: ${props => props.task.completed && !props.task.reward_received ? '#555' : 'auto'};
    padding: 5px 10px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
`;



export const OfficialButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin: 20px 0;
    width: calc(100% - 20px);
    max-width: 500px;
`;

export const OfficialButton = styled.button`
    background-color: #222;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;

    &:hover {
        background-color: #555;
    }
`;