import styled from 'styled-components';


// Стили для Invite компонента
export const InviteContainer = styled.div`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const InviteCard = styled.div`
    margin-top: 5px;
    width: 337px;
    height: 84px;
    background: linear-gradient(278.67deg, #B57E10 -53.51%, #B57E10 -26.08%, #E5C35C 30.93%, #F9DF7B 58.07%, #FFF3A6 77.64%, #F9DF7B 99.18%, #B57E10 128.54%);
    border-radius: 12px;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    & > div {
        position: absolute;
        background-color: #EEC95D;
        width: 68px;
        height: 68px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
    }


`;

export const InviteBenefits = styled.div`
    background: #1B1A19;
    border-radius: 12px;
    width: 337px;
    height: 134px;
    margin-top: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .invite-benefits-title {
        font-size: 20px;
        line-height: 24px;

    }

    & .invite-benefits {
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
        width: 100%;
        

    }

    & .invite-benefits-block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    & .invite-percent {


        font-family: 'GothamMedium', sans-serif;
        font-weight: 500;
        font-size: 35px;
        line-height: 42px;


    }

    & .invite-percent-description {
        font-size: 12px;
        line-height: 14px;
        color: #9E9E9E;


    }
`;

export const InviteCount = styled.div`
    margin-top: 60px;

    font-family: 'GothamMedium', sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
`;

export const InviteButtons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        background: #ffcc00;
        color: #000;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 20px;
        margin: 0.5rem 0;
        font-size: 1.2rem;
        cursor: pointer;
        transition: background 0.2s;

        &:hover {
            background: #e6b800;
        }

        &:nth-child(2) {
            background: #fff;
            color: #000;
        }
    }
`;


export const ButtonContainer = styled.div`
    background: linear-gradient(250.35deg, #B57E10 -23.78%, #B57E10 -3.87%, #E5C35C 37.49%, #F9DF7B 57.18%, #FFF3A6 71.38%, #F9DF7B 87.01%, #B57E10 108.32%);
    width: 336px;
    height: 47px;
    border-radius: 50px;
    filter: drop-shadow(0px 0px 25px rgba(253, 235, 148, 0.65));
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
    

    font-family: 'GothamMedium', serif;
    text-decoration: none;
    color: #000; /* Цвет текста */
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;


    &:hover {
        background: linear-gradient(250.35deg, #d79a24 -23.78%, #d79a24 -3.87%, #f5d176 37.49%, #fde88d 57.18%, #fff7c3 71.38%, #fde88d 87.01%, #d79a24 108.32%);
    }
`;


export const CopyLinkContainer = styled.div`
    border: 1px solid #575757;
    width: 336px;
    height: 47px;
    border-radius: 50px;
    background: black;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & p {
        color: #9E9E9E;
        font-size: 14px;
        line-height: 20px;
        margin-left: 20px;
    }

    & div {
        background: #3A3A3A;
        height: 30px;
        min-width: 30px;
        width: 30px;
        border-radius: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
    }
`


export const TotalFriendsStats = styled.div`
    width: 336px;
    height: 200px;
    background: #1B1A19;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 30px;

    & .title {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;

        margin-top: 15px;
    }

    & .total-amount {

        display: flex;
        align-items: center;
        gap: 5px;
        position: relative;
        font-family: 'GothamMedium', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 35px;
        line-height: 42px;

        margin-top: 15px;
        margin-bottom: 10px;

        & img {
            width: 34px;
            height: 34px;
        }
    }

    & .total-block {
        width: 305px;
        height: 69px;
        background: #242424;
        border-radius: 9px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-top: 10px;

        & .total-block_text {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;

            & img {
                width: 20px;
                height: 20px;
            }

            & p {
                margin-left: 5px;
            }

            & div {
                font-family: 'GothamMedium', sans-serif;
                margin-left: 10px;
                background: linear-gradient(250.35deg, #B57E10 -23.78%, #B57E10 -3.87%, #E5C35C 37.49%, #F9DF7B 57.18%, #FFF3A6 71.38%, #F9DF7B 87.01%, #B57E10 108.32%);
                color: #000;
                padding: 1px 5px;
                border-radius: 10px;
                min-width: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        & .total-block_amount {
            display: flex;
            align-items: center;

            & p {
                font-family: 'GothamMedium', sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
            }

            & img {
                width: 20px;
                height: 20px;
                padding-bottom: 2px;
            }

        }

    }
`

export const InviteFriendsListTitle = styled.p`
    font-family: 'GothamMedium', sans-serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    margin-top: 20px;
`

export const FriendList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: calc(100% - 20px);
    max-width: 500px;

`;


export const FriendItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    border-bottom: 1px solid #171717;
    ${props => props.isMe && "background: rgba(15, 24, 18, 0.8)"};    

    & .leaderbord-item-number {
        font-family: 'GothamMedium', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;

        background: ${(props) => {
            if (props.position === 1) {
                return 'linear-gradient(278.67deg, #B57E10 -53.51%, #B57E10 -26.08%, #E5C35C 30.93%, #F9DF7B 58.07%, #FFF3A6 77.64%, #F9DF7B 99.18%, #B57E10 128.54%);';
            } else if (props.position === 2) {
                return '#C5C7DC';
            } else if (props.position === 3) {
                return '#EFA457';
            } else {
                return '#404040';
            }
        }};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    & .leaderbord-item-name {
        font-family: 'GothamMedium', serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #A0A0A0;
    }

    & .leaderbord-item-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 7px;
    }

    & .leaderbord-item-friends {

        font-family: 'GothamMedium', serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
    }

    & .leaderbord-item-balance {
        font-family: 'GothamMedium', serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #0AFF96;
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            width: 17px; /* Ширина изображения */
            height: 17px; /* Высота изображения */
            background: url('/images/emblem.svg') no-repeat center center;
            background-size: contain;
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
        }

    }
`;