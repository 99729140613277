export const ClickerIcon = ({ color = '#B0B0B0' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.05 16.25H11.17C9.84001 16.25 8.75 15.13 8.75 13.75C8.75 13.34 9.09 13 9.5 13C9.91 13 10.25 13.34 10.25 13.75C10.25 14.3 10.66 14.75 11.17 14.75H13.05C13.44 14.75 13.75 14.4 13.75 13.97C13.75 13.43 13.6 13.35 13.26 13.23L10.25 12.18C9.61 11.96 8.75 11.49 8.75 10.02C8.75 8.76999 9.74001 7.73999 10.95 7.73999H12.83C14.16 7.73999 15.25 8.85999 15.25 10.24C15.25 10.65 14.91 10.99 14.5 10.99C14.09 10.99 13.75 10.65 13.75 10.24C13.75 9.68999 13.34 9.23999 12.83 9.23999H10.95C10.56 9.23999 10.25 9.58999 10.25 10.02C10.25 10.56 10.4 10.64 10.74 10.76L13.75 11.81C14.39 12.03 15.25 12.5 15.25 13.97C15.25 15.23 14.26 16.25 13.05 16.25Z" fill={color}/>
    <path d="M12 17.25C11.59 17.25 11.25 16.91 11.25 16.5V7.5C11.25 7.09 11.59 6.75 12 6.75C12.41 6.75 12.75 7.09 12.75 7.5V16.5C12.75 16.91 12.41 17.25 12 17.25Z" fill={color}/>
    <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C12.41 1.25 12.75 1.59 12.75 2C12.75 2.41 12.41 2.75 12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 11.59 21.59 11.25 22 11.25C22.41 11.25 22.75 11.59 22.75 12C22.75 17.93 17.93 22.75 12 22.75Z" fill={color}/>
    <path d="M21 7.75H17C16.59 7.75 16.25 7.41 16.25 7V3C16.25 2.59 16.59 2.25 17 2.25C17.41 2.25 17.75 2.59 17.75 3V6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z" fill={color}/>
    <path d="M16.9999 7.74994C16.8099 7.74994 16.6199 7.67994 16.4699 7.52994C16.1799 7.23994 16.1799 6.75994 16.4699 6.46994L21.4699 1.46994C21.7599 1.17994 22.2399 1.17994 22.5299 1.46994C22.8199 1.75994 22.8199 2.23994 22.5299 2.52994L17.5299 7.52994C17.3799 7.67994 17.1899 7.74994 16.9999 7.74994Z" fill={color}/>
  </svg>
);
export const TasksIcon = ({ color = '#B0B0B0' }) => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.62 9.62H12.37C11.96 9.62 11.62 9.28 11.62 8.87C11.62 8.46 11.96 8.12 12.37 8.12H17.62C18.03 8.12 18.37 8.46 18.37 8.87C18.37 9.28 18.04 9.62 17.62 9.62Z" fill={color}/>
        <path d="M7.12006 10.3799C6.93006 10.3799 6.74006 10.3099 6.59006 10.1599L5.84006 9.40995C5.55006 9.11995 5.55006 8.63995 5.84006 8.34995C6.13006 8.05995 6.61006 8.05995 6.90006 8.34995L7.12006 8.56995L8.84006 6.84995C9.13006 6.55995 9.61006 6.55995 9.90006 6.84995C10.1901 7.13995 10.1901 7.61995 9.90006 7.90995L7.65006 10.1599C7.51006 10.2999 7.32006 10.3799 7.12006 10.3799Z" fill={color}/>
        <path d="M17.62 16.62H12.37C11.96 16.62 11.62 16.28 11.62 15.87C11.62 15.46 11.96 15.12 12.37 15.12H17.62C18.03 15.12 18.37 15.46 18.37 15.87C18.37 16.28 18.04 16.62 17.62 16.62Z" fill={color}/>
        <path d="M7.12006 17.3799C6.93006 17.3799 6.74006 17.3099 6.59006 17.1599L5.84006 16.4099C5.55006 16.1199 5.55006 15.6399 5.84006 15.3499C6.13006 15.0599 6.61006 15.0599 6.90006 15.3499L7.12006 15.5699L8.84006 13.8499C9.13006 13.5599 9.61006 13.5599 9.90006 13.8499C10.1901 14.1399 10.1901 14.6199 9.90006 14.9099L7.65006 17.1599C7.51006 17.2999 7.32006 17.3799 7.12006 17.3799Z" fill={color}/>
        <path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" fill={color}/>
    </svg>

);

export const LeaderBordIcon = ({ color = '#B0B0B0' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.7001 19.73H7.30006C6.56006 19.73 5.81006 19.2 5.56006 18.51L1.42006 6.91998C0.910057 5.45998 1.28006 4.78998 1.68006 4.48998C2.08006 4.18998 2.83006 4.00998 4.09006 4.90998L7.99006 7.69998C8.11006 7.76998 8.22006 7.79998 8.30006 7.77998C8.39006 7.74998 8.46006 7.66998 8.51006 7.52998L10.2701 2.83998C10.8001 1.43998 11.5801 1.22998 12.0001 1.22998C12.4201 1.22998 13.2001 1.43998 13.7301 2.83998L15.4901 7.52998C15.5401 7.65998 15.6101 7.74998 15.7001 7.77998C15.7901 7.80998 15.9001 7.77998 16.0101 7.68998L19.6701 5.07998C21.0101 4.11998 21.7901 4.30998 22.2201 4.61998C22.6401 4.93998 23.0301 5.64998 22.4801 7.19998L18.4401 18.51C18.1901 19.2 17.4401 19.73 16.7001 19.73ZM2.68006 5.80998C2.70006 5.94998 2.74006 6.14998 2.84006 6.40998L6.98006 18C7.02006 18.1 7.20006 18.23 7.30006 18.23H16.7001C16.8101 18.23 16.9901 18.1 17.0201 18L21.0601 6.69998C21.2001 6.31998 21.2401 6.05998 21.2501 5.90998C21.1001 5.95998 20.8701 6.06998 20.5401 6.30998L16.8801 8.91998C16.3801 9.26998 15.7901 9.37998 15.2601 9.21998C14.7301 9.05998 14.3001 8.63998 14.0801 8.06998L12.3201 3.37998C12.1901 3.02998 12.0701 2.85998 12.0001 2.77998C11.9301 2.85998 11.8101 3.02998 11.6801 3.36998L9.92006 8.05998C9.71006 8.62998 9.28006 9.04998 8.74006 9.20998C8.21006 9.36998 7.61006 9.25998 7.12006 8.90998L3.22006 6.11998C2.99006 5.95998 2.81006 5.85998 2.68006 5.80998Z" fill={color}/>
    <path d="M17.5 22.75H6.5C6.09 22.75 5.75 22.41 5.75 22C5.75 21.59 6.09 21.25 6.5 21.25H17.5C17.91 21.25 18.25 21.59 18.25 22C18.25 22.41 17.91 22.75 17.5 22.75Z" fill={color}/>
    <path d="M14.5 14.75H9.5C9.09 14.75 8.75 14.41 8.75 14C8.75 13.59 9.09 13.25 9.5 13.25H14.5C14.91 13.25 15.25 13.59 15.25 14C15.25 14.41 14.91 14.75 14.5 14.75Z" fill={color}/>
  </svg>
);

export const MarketIcon = ({ color = '#B0B0B0' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.7 22.75H9.30001C4.36001 22.75 2.26001 20.64 2.26001 15.71V11.22C2.26001 10.81 2.60001 10.47 3.01001 10.47C3.42001 10.47 3.76001 10.81 3.76001 11.22V15.71C3.76001 19.8 5.21001 21.25 9.30001 21.25H14.69C18.78 21.25 20.23 19.8 20.23 15.71V11.22C20.23 10.81 20.57 10.47 20.98 10.47C21.39 10.47 21.73 10.81 21.73 11.22V15.71C21.74 20.64 19.63 22.75 14.7 22.75Z" fill={color}/>
    <path d="M12 12.75C10.9 12.75 9.9 12.32 9.19 11.53C8.48 10.74 8.15 9.71 8.26 8.61L8.93 1.93C8.97 1.55 9.29 1.25 9.68 1.25H14.35C14.74 1.25 15.06 1.54 15.1 1.93L15.77 8.61C15.88 9.71 15.55 10.74 14.84 11.53C14.1 12.32 13.1 12.75 12 12.75ZM10.35 2.75L9.75 8.76C9.68 9.43 9.88 10.06 10.3 10.52C11.15 11.46 12.85 11.46 13.7 10.52C14.12 10.05 14.32 9.42 14.25 8.76L13.65 2.75H10.35Z" fill={color}/>
    <path d="M18.31 12.75C16.28 12.75 14.47 11.11 14.26 9.09L13.56 2.08C13.54 1.87 13.61 1.66 13.75 1.5C13.89 1.34 14.09 1.25 14.31 1.25H17.36C20.3 1.25 21.67 2.48 22.08 5.5L22.36 8.28C22.48 9.46 22.12 10.58 21.35 11.43C20.58 12.28 19.5 12.75 18.31 12.75ZM15.14 2.75L15.76 8.94C15.89 10.19 17.05 11.25 18.31 11.25C19.07 11.25 19.75 10.96 20.24 10.43C20.72 9.9 20.94 9.19 20.87 8.43L20.59 5.68C20.28 3.42 19.55 2.75 17.36 2.75H15.14Z" fill={color}/>
    <path d="M5.64002 12.75C4.45002 12.75 3.37002 12.28 2.60002 11.43C1.83002 10.58 1.47002 9.46 1.59002 8.28L1.86002 5.53C2.28002 2.48 3.65002 1.25 6.59002 1.25H9.64002C9.85002 1.25 10.05 1.34 10.2 1.5C10.35 1.66 10.41 1.87 10.39 2.08L9.69002 9.09C9.48002 11.11 7.67002 12.75 5.64002 12.75ZM6.59002 2.75C4.40002 2.75 3.67002 3.41 3.35002 5.7L3.08002 8.43C3.00002 9.19 3.23002 9.9 3.71002 10.43C4.19002 10.96 4.87002 11.25 5.64002 11.25C6.90002 11.25 8.07002 10.19 8.19002 8.94L8.81002 2.75H6.59002Z" fill={color}/>
    <path d="M14.5 22.75H9.5C9.09 22.75 8.75 22.41 8.75 22V19.5C8.75 17.4 9.9 16.25 12 16.25C14.1 16.25 15.25 17.4 15.25 19.5V22C15.25 22.41 14.91 22.75 14.5 22.75ZM10.25 21.25H13.75V19.5C13.75 18.24 13.26 17.75 12 17.75C10.74 17.75 10.25 18.24 10.25 19.5V21.25Z" fill={color}/>
  </svg>
);

export const InvitesIcon = ({ color = '#B0B0B0' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.15957 11.62C9.12957 11.62 9.10957 11.62 9.07957 11.62C9.02957 11.61 8.95957 11.61 8.89957 11.62C5.99957 11.53 3.80957 9.25 3.80957 6.44C3.80957 3.58 6.13957 1.25 8.99957 1.25C11.8596 1.25 14.1896 3.58 14.1896 6.44C14.1796 9.25 11.9796 11.53 9.18957 11.62C9.17957 11.62 9.16957 11.62 9.15957 11.62ZM8.99957 2.75C6.96957 2.75 5.30957 4.41 5.30957 6.44C5.30957 8.44 6.86957 10.05 8.85957 10.12C8.91957 10.11 9.04957 10.11 9.17957 10.12C11.1396 10.03 12.6796 8.42 12.6896 6.44C12.6896 4.41 11.0296 2.75 8.99957 2.75Z" fill={color}/>
    <path d="M16.5396 11.75C16.5096 11.75 16.4796 11.75 16.4496 11.74C16.0396 11.78 15.6196 11.49 15.5796 11.08C15.5396 10.67 15.7896 10.3 16.1996 10.25C16.3196 10.24 16.4496 10.24 16.5596 10.24C18.0196 10.16 19.1596 8.96 19.1596 7.49C19.1596 5.97 17.9296 4.74 16.4096 4.74C15.9996 4.75 15.6596 4.41 15.6596 4C15.6596 3.59 15.9996 3.25 16.4096 3.25C18.7496 3.25 20.6596 5.16 20.6596 7.5C20.6596 9.8 18.8596 11.66 16.5696 11.75C16.5596 11.75 16.5496 11.75 16.5396 11.75Z" fill={color}/>
    <path d="M9.16961 22.55C7.20961 22.55 5.23961 22.05 3.74961 21.05C2.35961 20.13 1.59961 18.87 1.59961 17.5C1.59961 16.13 2.35961 14.86 3.74961 13.93C6.74961 11.94 11.6096 11.94 14.5896 13.93C15.9696 14.85 16.7396 16.11 16.7396 17.48C16.7396 18.85 15.9796 20.12 14.5896 21.05C13.0896 22.05 11.1296 22.55 9.16961 22.55ZM4.57961 15.19C3.61961 15.83 3.09961 16.65 3.09961 17.51C3.09961 18.36 3.62961 19.18 4.57961 19.81C7.06961 21.48 11.2696 21.48 13.7596 19.81C14.7196 19.17 15.2396 18.35 15.2396 17.49C15.2396 16.64 14.7096 15.82 13.7596 15.19C11.2696 13.53 7.06961 13.53 4.57961 15.19Z" fill={color}/>
    <path d="M18.3396 20.75C17.9896 20.75 17.6796 20.51 17.6096 20.15C17.5296 19.74 17.7896 19.35 18.1896 19.26C18.8196 19.13 19.3996 18.88 19.8496 18.53C20.4196 18.1 20.7296 17.56 20.7296 16.99C20.7296 16.42 20.4196 15.88 19.8596 15.46C19.4196 15.12 18.8696 14.88 18.2196 14.73C17.8196 14.64 17.5596 14.24 17.6496 13.83C17.7396 13.43 18.1396 13.17 18.5496 13.26C19.4096 13.45 20.1596 13.79 20.7696 14.26C21.6996 14.96 22.2296 15.95 22.2296 16.99C22.2296 18.03 21.6896 19.02 20.7596 19.73C20.1396 20.21 19.3596 20.56 18.4996 20.73C18.4396 20.75 18.3896 20.75 18.3396 20.75Z" fill={color}/>
  </svg>
);