import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL + '/api';

export const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
        'Authentication': window.Telegram.WebApp.initData,
        'ngrok-skip-browser-warning': 'true', // Skip warning when using ngrok for local development
        'bypass-tunnel-reminder': 'true', // Skip warning when using localtunnel for local development
    },
});