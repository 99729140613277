import React, {useEffect, useState} from 'react';
import {LeaderboardContainer, UserList, UserItem, FilterButtons} from './styles';
import Footer from "../../elements/Footer/Footer";
import {axiosInstance} from "../../api";

const Leaderboard = () => {
    const [users, setUsers] = useState([]);
    const [me, setMe] = useState(null);

    const [intervalIndex, setIntervalIndex] = useState(0);
    const intervals = ['day', 'week', 'month', 'all_time'];

    const leadersInterval = intervals[intervalIndex];

    const intervalToStr = {
        'day': 'День',
        'week': 'Тиждень',
        'month': 'Місяць',
        'all_time': 'Весь час'
    };

    useEffect(() => {
        axiosInstance(`/user/leaderboard?interval=${leadersInterval}`).then(response => {
            setUsers(response.data.data);
        });
        axiosInstance(`/user/referrals?interval=${leadersInterval}`).then(response => {
            setMe(response.data.data);
        });
    }, [leadersInterval]);

    return (
        <>
            <LeaderboardContainer>
                <FilterButtons>
                    {intervals.map((interval, index) => (
                        <button
                            key={interval}
                            onClick={() => setIntervalIndex(index)}
                            style={{
                                backgroundColor: intervalIndex === index ? '#555' : '#222',
                                color: intervalIndex === index ? '#fff' : '#959595'
                            }}
                        >
                            <span>{intervalToStr[interval]}</span>
                        </button>
                    ))}
                </FilterButtons>
                <UserList>
                    {me && <User user={me} isMe={true}/>}
                    {users.map((user, index) => (
                        <User key={user.id} user={user} position={index + 1}/>
                    ))}
                </UserList>
            </LeaderboardContainer>
            <Footer/>
        </>
    );
}

function User({user, position = null, isMe = false}) {
    return (
        <UserItem position={position} isMe={isMe}>
            <div className="leaderbord-item-left">
                <p className="leaderbord-item-number">{position && `#${position}`}</p>
                <p className="leaderbord-item-name">{user.username}</p>
            </div>
            <div className="leaderbord-item-right">
                <p className="leaderbord-item-friends">Запрошені друзі: {user.friends_first_level + user.friends_second_level}</p>
                <p className="leaderbord-item-balance">{parseFloat(user.wallet_balance).toFixed(0)}</p>
            </div>
        </UserItem>
    );
}

export default Leaderboard;