import Footer from "../../elements/Footer/Footer";
import {MarketContainer} from "./styles";

const Market = () => {
    return (
        <>
            <MarketContainer>
                <p>Скоро буде...</p>
            </MarketContainer>
            <Footer/>
        </>
    )
}

export default Market;