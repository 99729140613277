import styled from 'styled-components';


export const LeaderboardContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const FilterButtons = styled.div`
    display: flex;
    justify-content: center;
    gap: 10px;
    max-width: 500px;
    width: calc(100% - 20px);
    margin-top: 30px;

    button {
        display: flex;
        align-items: center;
        justify-content: space-around;
        gap: 5px;

        width: 85px;
        height: 27px;

        color: #959595;
        background-color: #222;
        border: 1px solid;
        border-radius: 30px;
        cursor: pointer;
        outline: none;
        transition: all 0.3s ease;

        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.02em;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);


        &:hover {
            background: #555;
        }
    }
`;

export const UserList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    width: calc(100% - 20px);
    max-width: 500px;

`;

export const UserItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    margin-top: 15px;
    border-bottom: 1px solid #171717;
    ${props => props.isMe && "background: rgba(15, 24, 18, 0.8)"};    

    & .leaderbord-item-number {
        font-family: 'GothamMedium', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;

        background: ${(props) => {
            if (props.position === 1) {
                return 'linear-gradient(278.67deg, #B57E10 -53.51%, #B57E10 -26.08%, #E5C35C 30.93%, #F9DF7B 58.07%, #FFF3A6 77.64%, #F9DF7B 99.18%, #B57E10 128.54%);';
            } else if (props.position === 2) {
                return '#C5C7DC';
            } else if (props.position === 3) {
                return '#EFA457';
            } else {
                return '#404040';
            }
        }};
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }

    & .leaderbord-item-name {
        font-family: 'GothamMedium', serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #A0A0A0;
    }

    & .leaderbord-item-right {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 7px;
    }

    & .leaderbord-item-friends {

        font-family: 'GothamMedium', serif;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.02em;
    }

    & .leaderbord-item-balance {
        font-family: 'GothamMedium', serif;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.02em;
        color: #0AFF96;
        position: relative;

        &::before {
            content: '';
            display: inline-block;
            width: 17px; /* Ширина изображения */
            height: 17px; /* Высота изображения */
            background: url('/images/emblem.svg') no-repeat center center;
            background-size: contain;
            position: absolute;
            left: -20px;
            top: 50%;
            transform: translateY(-50%);
        }

    }
`;
