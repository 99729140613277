import React from 'react';
import {FooterContainer, FooterWrapper, NavItem} from './styles';
import {ClickerIcon, InvitesIcon, LeaderBordIcon, MarketIcon, TasksIcon} from './icons';

export default function Footer() {

    const vibrate = () => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');
    };

    return (
        <FooterWrapper>
            <FooterContainer>
                <FooterButton path="/farm" name="Ферма" IconComponent={ClickerIcon}/>
                <FooterButton path="/Tasks" name="Завдання" IconComponent={TasksIcon}/>
                <FooterButton path="/leaderboard" name="Лідери" IconComponent={LeaderBordIcon}/>
                <FooterButton path="/market" name="Магазин" IconComponent={MarketIcon}/>
                <FooterButton path="/invite" name="Друзі" IconComponent={InvitesIcon}/>
            </FooterContainer>
        </FooterWrapper>
    );
}


const FooterButton = ({path, name, IconComponent}) => {

    const vibrate = () => {
        window.Telegram.WebApp.HapticFeedback.impactOccurred('soft');
    };

    return (
        <NavItem onClick={vibrate} to={path} exact activeClassName="active">
            {({isActive}) => (
                <>
                    <IconComponent color={isActive ? '#B57E10' : '#B0B0B0'}/>
                    {name}
                </>
            )}
        </NavItem>
    )
};