import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const FooterWrapper = styled.div`
    margin-top: 110px;
`;

export const FooterContainer = styled.div`
    position: fixed;
    bottom: 0;
    height: 75px;
    display: flex;
    margin-top: 1rem;
    justify-content: center;
    gap: 3px;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
    padding-bottom: 34px;

    & .nav-bar {
        display: flex;
    }
`;

export const NavItem = styled(NavLink)`
    font-family: GothamMedium, sans-serif;
    background-color: #626262;
    height: 60px;
    width: 76px;

    font-weight: 500;
    font-size: 10px;
    line-height: 12px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    color: #fff;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s ease-in-out;

    &.active {
        background: linear-gradient(250.35deg, #B57E10 -23.78%, #B57E10 -3.87%, #E5C35C 37.49%, #F9DF7B 57.18%, #FFF3A6 71.38%, #F9DF7B 87.01%, #B57E10 108.32%);
        color: #000;
    }

    &:hover {
        background-color: #525252;
    }

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 300%;
        height: 300%;
        background: rgba(255, 255, 255, 0.2);
        transition: width 0.3s ease, height 0.3s ease, top 0.3s ease, left 0.3s ease;
        border-radius: 50%;
        pointer-events: none;
        transform: translate(-50%, -50%);
    }

    &:active:before,
    &:focus:before {
        width: 0;
        height: 0;
        top: 50%;
        left: 50%;
        transition: width 0.1s ease, height 0.1s ease, top 0.1s ease, left 0.1s ease;
    }
`;