import React, {useEffect, useState} from "react";
import {useNavigate} from 'react-router-dom';

import {
    CoinImage,
    DailyRewardContainer,
    DailyRewardItemContainer,
    DailyRewardsList,
    DailyRewardText,
    DailyRewardText2,
    DailyRewardItemLeft,
    DailyRewardItemLeftText1,
    DailyRewardItemLeftText2,
    DailyRewardItemTotal, DailyRewardItemRightText1, DailyRewardItemRightText2, ButtonContainer
} from "./styles";
import {axiosInstance} from "../../api";
import confetti from "canvas-confetti";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";


const STATUS = {
    false: "В очікуванні",
    true: "Отримано",
}

export default function DailyReward() {
    const [rewards, setRewards] = useState()
    const {executeRecaptcha} = useGoogleReCaptcha();
    const navigate = useNavigate()

    useEffect(() => {
        axiosInstance.get('/user/daily_rewards').then(response => {
            if (response.status === 200) {
                setRewards(response.data.data)
            } else {
                navigate('/farm')
            }
        }).catch(() => {
            navigate('/farm')
        })
    }, [])


    async function claimReward() {
        confetti({
            particleCount: 150,
            spread: 70,
            origin: {y: 0.9},
            shapes: ['circle'],
            colors: ['FFE400', 'FFBD00', 'E89400', 'FFCA6C', 'FDFFB8']
        });

        try {
            const token = await executeRecaptcha('set_rewards');
        await axiosInstance.post('/user/set_reward', {}, {
            headers: {
                'X-Recaptcha-Token': token
            }
        })
        window.Telegram.WebApp.HapticFeedback.impactOccurred("soft");
        navigate('/farm')
        } catch (e) {


        }
    }


    return (
        <DailyRewardContainer>
            <CoinImage src="/images/coin.svg" alt="coin"/>
            <DailyRewardText>Щоденний бонус</DailyRewardText>
            <DailyRewardText2>Повертайся завтра, щоб отримати більше</DailyRewardText2>
            <DailyRewardsList>


                {rewards && rewards.map((reward, index) => (
                    <DailyRewardItem key={index} day={reward.day_count} date={reward.day_count} total={reward.reward}
                                     status={reward.status}/>
                ))}

            </DailyRewardsList>
            <ButtonContainer onClick={claimReward}>Отримати бонус</ButtonContainer>
        </DailyRewardContainer>
    )
}


function DailyRewardItem({day, date, total, status}) {
    return (
        <DailyRewardItemContainer>
            <DailyRewardItemLeft>
                <img src={status ? "/images/reward_emblem_light.svg" : "/images/reward_emblem_dark.svg"} alt=""
                     width="45"/>
                <div>
                    <DailyRewardItemLeftText1>День {day}</DailyRewardItemLeftText1>
                    <DailyRewardItemLeftText2></DailyRewardItemLeftText2>
                </div>
            </DailyRewardItemLeft>
            <div>
                <DailyRewardItemTotal>
                    <img src="/images/emblem.svg" alt="arrow"/>
                    <DailyRewardItemRightText1>{parseFloat(total).toFixed(2)}</DailyRewardItemRightText1>
                </DailyRewardItemTotal>
                <DailyRewardItemRightText2 status={status}>{STATUS[status]}</DailyRewardItemRightText2>
            </div>
        </DailyRewardItemContainer>
    )
}