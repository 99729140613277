import styled from "styled-components";
import {ReactComponent as BgSvg} from './bg_brand.svg';



export const BackgroundContainer = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, #000000 0%, #000000 100%);
    z-index: -1;
`;

export const BrandSVGLeft = styled(BgSvg)`
    position: absolute;
    left: calc(50% - 655px / 2 + 171px);
    top: -100px;
`;

export const BrandSVGRight = styled(BgSvg)`
    position: absolute;
    left: calc(50% - 655px / 2 + 373px);
    top: 200px;
`;



const BaseEllipse = styled.div`
    position: fixed;
    width: 173px;
    height: 156px;
    background: linear-gradient(278.67deg, #B57E10 -53.51%, #B57E10 -26.08%, #E5C35C 30.93%, #F9DF7B 58.07%, #FFF3A6 77.64%, #F9DF7B 99.18%, #B57E10 128.54%);
    opacity: 0.30;
    filter: blur(75px);
`;

export const Ellipse1 = styled(BaseEllipse)`
    left: -70px;
    top: 94px;
`;

export const Ellipse2 = styled(BaseEllipse)`
    right: -70px;
    top: 250px;
`;

