import React from "react";
import {BackgroundContainer, BrandSVGLeft, BrandSVGRight, Ellipse1, Ellipse2} from './styles';

export default function Background() {
    return (
        <BackgroundContainer>
            <BrandSVGLeft/>
            <BrandSVGRight/>
            <Ellipse1/>
            <Ellipse2/>
        </BackgroundContainer>
    )
}
