import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3'
import {REACT_APP_CAPTCHA_SITE_KEY} from "./components/constants";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <GoogleReCaptchaProvider reCaptchaKey={REACT_APP_CAPTCHA_SITE_KEY}>
            <App/>
        </GoogleReCaptchaProvider>
    </React.StrictMode>
);
